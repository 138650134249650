// libs
import React from "react";

// components
import { Modal, Grid, Stack, Typography, LinearProgress } from "@mui/material";

// colors
import { Success } from "../../../styles/colors";

// styles
import { linearLoaderContainer, linearLoaderStack, linearLoader } from "./LinearLoader.style";

const LinearLoader: React.FC = () => {
    return (
        <Modal open={true} disableEscapeKeyDown={true} onClose={() => false}>
            <Grid container alignContent="center" justifyContent="center" sx={linearLoaderContainer}>
                <Stack alignItems="center" sx={linearLoaderStack} spacing={1}>
                    <Typography color={Success.white} variant="h6">
                        Loading...
                    </Typography>
                    <LinearProgress sx={linearLoader} />
                </Stack>
            </Grid>
        </Modal>
    );
};

export default React.memo(LinearLoader);
