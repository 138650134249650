// libs
import React, { lazy } from "react";
import { Router } from "@remix-run/router";
import { RouteObject, createBrowserRouter, matchRoutes } from "react-router-dom";

// components
import PageContainer from "./components/navigation/PageContainer";

// utils
import { configureAmplify } from "@utils/appsync";

// screens
const Home = lazy(() => import("@pages/Home"));
const AllocationConfig = lazy(() => import("@pages/AllocationConfig"));
const NewSimulation = lazy(() => import("@pages/simulations/NewSimulation"));
const SimulationResult = lazy(() => import("pages/simulations/SimulationResult"));
const SimulationHistory = lazy(() => import("@pages/simulations/SimulationHistory"));
const SandboxesList = lazy(() => import("@pages/control-plane/sandboxes/SandboxesList"));

export const SANDBOX_ID_PARAM = "sandboxId";

export const ALLOCATION_TYPE_PARAM = "allocationType";

export const SIMULATIONS_ROUTES = [{ path: "/simulations" }];

export const SIMULATION_ROUTES = [{ path: "/simulation/:resourceKey" }, { path: "/simulation/result/:resourceKey" }];

export const DICTIONARY_ROUTES = [{ path: "/dictionary" }, { path: "/simulation/:inputType" }];

export const CONTROL_PLANE_ROUTES = [{ path: "//control-plan" }, { path: "//control-plan/sandboxes" }];

// Helper function to match multiple routes
export const isCurrentRouteMatching = (currentRoute: string, routes: Array<RouteObject>): boolean => {
    if (matchRoutes(routes, currentRoute)) {
        return true;
    }
    return false;
};

export const getParamFromRouteQuery = (routeQueryString: string, paramName: string): string => {
    if (!routeQueryString) {
        return "";
    }
    const urlSearchParams = new URLSearchParams(routeQueryString);
    return urlSearchParams.get(paramName);
};

export const getRouter = (): Router => {
    const setupLoader = async (): Promise<unknown> => {
        const settingsData = await fetch("/a3s-settings.json").then((res) => res.json());
        const cognitoInfo = await configureAmplify(settingsData);
        return {
            settingsData,
            cognitoInfo,
        };
    };

    return createBrowserRouter([
        {
            element: <PageContainer />,
            loader: setupLoader,
            children: [
                {
                    path: "/",
                    element: <Home />,
                },
                {
                    path: "/simulations",
                    element: <SimulationHistory />,
                },
                {
                    path: "/simulation",
                    children: [
                        {
                            path: "",
                            element: <NewSimulation />,
                        },
                        {
                            path: "result/:resourceKey",
                            element: <SimulationResult />,
                        },
                        {
                            path: ":resourceKey",
                            element: <NewSimulation />,
                        },
                    ],
                },
                {
                    path: "/dictionary",
                    children: [
                        {
                            path: "",
                            element: <AllocationConfig />,
                        },
                        {
                            path: ":inputType",
                            element: <AllocationConfig />,
                        },
                    ],
                },
                {
                    path: "/control-plane",
                    children: [
                        {
                            path: "/control-plane/sandboxes",
                            element: <SandboxesList />,
                        },
                    ],
                },
            ],
        },
    ]);
};
